<template>
  <div class="main">
    <div class="header">
      <img :src="qrcode" class="qrcode" />
      <div class="date">{{date}}</div>
      <div class="title hp-font">{{club.name}}活动日历</div>
      <div
        class="avatar"
        :style="
          'background-image: url(' +
          club.avatar +
          ')'" />
      <div class="name-wrap">
        <div class="name">{{club.name}}</div>
        <div class="club-icon-wrap"><div class="club-icon"></div></div>
      </div>
    </div>
    <div class="info">
      <div class="desc">{{club.desc}}</div>
    </div>
    <div class="campaigns">
      <div class="card" v-for="item of campaignsByDate" :key="item.date">
        <div class="card-title">{{item.date}}</div>
        <div class="card-body">
          <div class="card-body-rows" v-for="campaign of item.campaigns">
            <div class="card-body-rows-item">{{campaign.name}}</div>
            <div class="card-body-rows-item center">{{campaign.times}}</div>
            <div class="card-body-rows-item">{{campaign.address}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <img :src="qrcode" class="qrcode" />
    </div>
  </div>
</template>
<script>
import $fetch from '@/utils/fetch.js';
import dayjs from 'dayjs';

export default {
  name: 'club_week',
  data() {
    return {
      date: '',
      qrcode: '',
      club: {
        name: '',
        desc: ''
      },
      campaignsByDate: [
      ]
    }
  },
  created() {
    const { club_id } = this.$route.query;
    const beginDate = dayjs().format('MMDD');
    const endDate = dayjs().add(7, 'day').format('MMDD');
    this.date = `${beginDate}-${endDate}`;
    $fetch.post("ShareProfile", {userId: club_id}).then(r => {
      this.qrcode = 'data:image/jpeg;base64, ' + r.mini_program_code_image
    });
    $fetch.post("ListCampaignsOfClub", {
      club_id
    }).then(res => {
      const { club, items } = res;
      console.log(club, items);
      this.club.name = club.nickname;
      this.club.desc = club.sign;
      this.club.avatar = club.avatar_url;
      const resolvedCampaigns = items.map(item => {
        const c = item.campaign;
        const startTime = dayjs(c.start_time);
        const endTime = dayjs(c.end_time);
        const times = `${startTime.format('HH:mm')}-${endTime.format('HH:mm')}`;
        const day = startTime.day();
        return {
          name: c.title,
          day: day === 0 ? 7 : day,
          date: startTime.format('MMDD'),
          times,
          address: item.address
        };
      });
      const map = {

      };
      for (const cam of resolvedCampaigns) {
        if (!map[cam.date]) {
          map[cam.date] = []
        }
        map[cam.date].push({
          name: cam.name,
          times: cam.times,
          address: cam.address,
          day: cam.day
        });
      }
      console.log(resolvedCampaigns);
      console.log(map);
      const dayArray = ['周一', '周二', '周三', '周四', '周五', '周六', '周天' ];
      const campaignsByDate = Object.keys(map).map(key => {
        console.log(key, map[key]);
        const d = map[key][0];
        console.log(d);
        return {
          date: `${key} ${dayArray[d.day - 1]}`,
          campaigns: map[key]
        }
      });
      this.campaignsByDate = campaignsByDate;
    });
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/global.scss";
  .hp-font {
    font-family: "Alibaba PuHuiTi Heavy", Alibaba-PuHuiTi;
  }
  div {
    box-sizing: border-box;
  }
  .main {
    min-height: 100vh;
    background: #000;
    > .header {
      background: border-box url("../assets/images/club-week-top-bg.png") no-repeat;
      background-size: 100%;
      width: 100%;
      height: px2rem(658);
      position: relative;
      padding-left: px2rem(80);
      padding-top: px2rem(68);
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      > .qrcode {
        width: px2rem(144);
        height: px2rem(144);
        margin-bottom: px2rem(12);
      }
      > .date {
        background: #000;
        border-radius: px2rem(8);
        height: px2rem(56);
        font-size: px2rem(32);
        font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
        font-weight: normal;
        color: #FFFFFF;
        line-height: px2rem(32);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0 px2rem(16);
      }
      > .title {
        margin-top: px2rem(16);
        font-size: px2rem(64);
        font-family: "Alibaba PuHuiTi Heavy", Alibaba-PuHuiTi;
        font-weight: normal;
        color: #000000;
        line-height: px2rem(88);
        padding-right: px2rem(80);
      }
      > .avatar {
        position: absolute;
        width: px2rem(192);
        height: px2rem(192);
        background-size: cover;
        border-radius: 50%;
        right: px2rem(32);
        top: px2rem(412);
      }
      > .name-wrap {
        margin-bottom: px2rem(16);
        position: absolute;
        bottom: 0;
        left: px2rem(32);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        height: px2rem(54);
        > .name {
          font-size: px2rem(40);
          font-family: "Alibaba PuHuiTi Medium", Alibaba-PuHuiTi;
          font-weight: normal;
          color: #FFFFFF;
          line-height: px2rem(54);
        }
        > .club-icon-wrap {
          margin-left: px2rem(10);
          width: px2rem(56);
          height: px2rem(32);
          background: #059AFF;
          border-radius: px2rem(6);
          display: flex;
          align-items: center;
          justify-content: center;
          > .club-icon {
            background: border-box url("../assets/images/club.png") no-repeat;
            background-size: px2rem(48) px2rem(18);
            width: px2rem(48);
            height: px2rem(18);
          }
        }
      }
    }
    > .info {
      padding: px2rem(40) px2rem(32);
      padding-top: 0;
      > .desc {
        font-size: px2rem(28);
        font-family: "Alibaba PuHuiTi Medium", "PingFang SC Regular", "PingFang SC";
        font-weight: 400;
        color: #FFFFFF;
        line-height: px2rem(48);
      }
    }
    > .campaigns {
      padding: 0 px2rem(32);
      margin-bottom: px2rem(78);
      > .card {
        margin-bottom: px2rem(24);
        &:last-child {
          margin-bottom: 0;
        }
        > .card-title {
          display: flex;
          align-items: center;
          justify-content: center;
          width: px2rem(198);
          height: px2rem(64);
          background: linear-gradient(to right,#4BFDAA 30%, #C9F872);
          border-top-left-radius: px2rem(24);
          border-top-right-radius: px2rem(24);
          font-size: px2rem(32);
          font-family: "Alibaba PuHuiTi Heavy", Alibaba-PuHuiTi;
          font-weight: normal;
          color: #000000;
          line-height: px2rem(32);
        }
        > .card-body {
          background: #fff;
          padding: px2rem(24);
          border-radius: px2rem(16);
          border-top-left-radius: 0;
          > .card-body-rows {
            display: flex;
            flex-direction: row;
            border-radius: px2rem(16);
            background: linear-gradient(to right, #D4FFEA, #FBFFEF);
            margin-bottom: px2rem(16);
            &:last-child {
              margin-bottom: 0px;
            }
            > .card-body-rows-item {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: px2rem(24) px2rem(16);
              font-size: px2rem(28);
              font-family: "Alibaba PuHuiTi Medium", "PingFang SC Medium", "PingFang SC";
              font-weight: 500;
              color: #111111;
              line-height: px2rem(48);
              
              &.center {
                border: .5px solid rgba(0, 0, 0, 0.1);
                border-top: none;
                border-bottom: none;
              }
            }
          }
        }
      }
    }
    > .bottom {
      background: border-box url("../assets/images/club-week-bottom-bg.png") no-repeat;
      background-size: 100% px2rem(520);
      width: 100%;
      height: px2rem(520);
      position: relative;

      > .qrcode {
        width: px2rem(180);
        height: px2rem(180);
        left: px2rem(286);
        bottom: px2rem(188);
        position: absolute;
      }
    }
  }
</style>
